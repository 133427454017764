import {createAction, props} from '@ngrx/store';
import {SalesPackageDto, UpdateSalesPackageDto} from "../models/package.entities";


export const loadPackages = createAction('[User] Load Packages',props<{filter:any}>());
export const loadPackagesSuccess = createAction('[User] Load Packages Success', props<{ salesPackages: SalesPackageDto[] }>());
export const loadPackagesFailure = createAction('[User] Load Packages Failure', props<{ error: any }>());

export const addPackage = createAction('[Package] Add Package', props<{ salesPackage: SalesPackageDto }>());
export const addPackageSuccess = createAction('[Package] Add Package Success', props<{ salesPackage: SalesPackageDto }>());
export const addPackageFailure = createAction('[Package] Add Package Failure', props<{ error: any }>());

export const updatePackage = createAction('[Package] Update Package', props<{ id: number, salesPackage: UpdateSalesPackageDto }>());
export const updatePackageSuccess = createAction('[Package] Update Package Success', props<{ salesPackage: SalesPackageDto }>());
export const updatePackageFailure = createAction('[Package] Update Package Failure', props<{ error: any }>());

export const deletePackage = createAction('[Package] Delete Package', props<{ id: number }>());
export const deletePackageSuccess = createAction('[Package] Delete Package Success', props<{ id: number }>());
export const deletePackageFailure = createAction('[Package] Delete Package Failure', props<{ error: any }>());
